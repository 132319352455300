import React, { useState } from 'react';
import '../styles/DomainFilter.css';

function DomainFilter({ onFilterChange }) {
  const [filters, setFilters] = useState({
    tld: '',
    name: '',
    regDate: '',
    expDate: '',
    ns: '',
    registrar: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onFilterChange(filters);
  };

  return (
    <form onSubmit={handleSubmit} className="domain-filter">
      <input
        type="text"
        name="tld"
        value={filters.tld}
        onChange={handleChange}
        placeholder="TLD"
      />
      <input
        type="text"
        name="name"
        value={filters.name}
        onChange={handleChange}
        placeholder="Domain Name"
      />
      <input
        type="date"
        name="regDate"
        value={filters.regDate}
        onChange={handleChange}
        placeholder="Registration Date"
      />
      <input
        type="date"
        name="expDate"
        value={filters.expDate}
        onChange={handleChange}
        placeholder="Expiration Date"
      />
      <input
        type="text"
        name="ns"
        value={filters.ns}
        onChange={handleChange}
        placeholder="Nameserver"
      />
      <input
        type="text"
        name="registrar"
        value={filters.registrar}
        onChange={handleChange}
        placeholder="Registrar"
      />
      <button type="submit" className="btn filter-btn">Apply Filters</button>
    </form>
  );
}

export default DomainFilter;
