// src/services/api.js

const API_URL = process.env.REACT_APP_API_URL || 'https://domaindash.mohamedhelal.workers.dev';

async function fetchWithAuth(url, options = {}) {
  const user = JSON.parse(localStorage.getItem('user'));
  if (user && user.token) {
    options.headers = {
      ...options.headers,
      'Authorization': `Bearer ${user.token}`
    };
  }
  const response = await fetch(`${API_URL}${url}`, options);
  if (!response.ok) {
    const errorData = await response.json().catch(() => ({}));
    throw new Error(errorData.message || 'API request failed');
  }
  return response.json();
}

export async function login(email, password) {
  const response = await fetch(`${API_URL}/api/auth/login`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, password }),
  });
  if (!response.ok) {
    const errorData = await response.json().catch(() => ({}));
    throw new Error(errorData.message || 'Login failed');
  }
  return response.json();
}

export async function register(email, password, invitationCode) {
  const response = await fetch(`${API_URL}/api/auth/register`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, password, invitationCode }),
  });
  if (!response.ok) {
    const errorData = await response.json().catch(() => ({}));
    throw new Error(errorData.message || 'Registration failed');
  }
  return response.json();
}

export async function getDomains(filters = {}) {
  const queryParams = new URLSearchParams(filters).toString();
  return fetchWithAuth(`/api/domains?${queryParams}`);
}

export async function addDomain(domainData) {
  return fetchWithAuth('/api/domains', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(domainData),
  });
}

export async function updateDomain(id, domainData) {
  return fetchWithAuth(`/api/domains/${id}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(domainData),
  });
}

export async function deleteDomain(id) {
  return fetchWithAuth(`/api/domains/${id}`, {
    method: 'DELETE',
  });
}

export async function updateUserSettings(userId, settings) {
  return fetchWithAuth(`/api/users/${userId}/settings`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(settings),
  });
}

export async function getUserProfile() {
  return fetchWithAuth('/api/users/profile');
}

export async function logout() {
  // If your backend requires a logout API call, uncomment the next line
  // await fetchWithAuth('/api/auth/logout', { method: 'POST' });
  localStorage.removeItem('user');
}

export async function refreshToken() {
  const user = JSON.parse(localStorage.getItem('user'));
  if (user && user.refreshToken) {
    const response = await fetch(`${API_URL}/api/auth/refresh`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ refreshToken: user.refreshToken }),
    });
    if (response.ok) {
      const newTokens = await response.json();
      localStorage.setItem('user', JSON.stringify({ ...user, ...newTokens }));
      return newTokens;
    }
  }
  throw new Error('Unable to refresh token');
}
