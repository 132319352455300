import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { getDomains, addDomain, updateDomain, deleteDomain } from '../services/api';
import DomainList from '../components/DomainList';
import AddDomainForm from '../components/AddDomainForm';
import DomainFilter from '../components/DomainFilter';
import { exportToCSV } from '../utils/helpers';
import '../styles/Dashboard.css';

function Dashboard() {
  const [domains, setDomains] = useState([]);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user, logout } = useAuth();

  const fetchDomains = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const fetchedDomains = await getDomains(filters);
      setDomains(fetchedDomains);
    } catch (error) {
      console.error('Error fetching domains:', error);
      setError('Failed to fetch domains. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [filters]);

  useEffect(() => {
    fetchDomains();
  }, [fetchDomains]);

  const handleAddDomain = async (domainData) => {
    try {
      await addDomain(domainData);
      fetchDomains();
    } catch (error) {
      console.error('Error adding domain:', error);
      setError('Failed to add domain. Please try again.');
    }
  };

  const handleUpdateDomain = async (id, domainData) => {
    try {
      await updateDomain(id, domainData);
      fetchDomains();
    } catch (error) {
      console.error('Error updating domain:', error);
      setError('Failed to update domain. Please try again.');
    }
  };

  const handleDeleteDomain = async (id) => {
    try {
      await deleteDomain(id);
      fetchDomains();
    } catch (error) {
      console.error('Error deleting domain:', error);
      setError('Failed to delete domain. Please try again.');
    }
  };

  const handleExportCSV = () => {
    exportToCSV(domains, 'domain_portfolio.csv');
  };

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
  };

  if (loading) {
    return <div className="loading">Loading domains...</div>;
  }

  return (
    <div className="dashboard">
      <header className="dashboard-header">
        <h1>Domain Portfolio Dashboard</h1>
        <div className="user-info">
          <span>Welcome, {user.email}</span>
          <button onClick={logout} className="btn logout-btn">Logout</button>
        </div>
      </header>
      <main className="dashboard-main">
        {error && <div className="error-message">{error}</div>}
        <AddDomainForm onAddDomain={handleAddDomain} />
        <DomainFilter onFilterChange={handleFilterChange} />
        <DomainList 
          domains={domains} 
          onUpdateDomain={handleUpdateDomain}
          onDeleteDomain={handleDeleteDomain}
        />
        <button onClick={handleExportCSV} className="btn export-btn">Export as CSV</button>
      </main>
    </div>
  );
}

export default Dashboard;
