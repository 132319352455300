import React, { useState } from 'react';
import '../styles/AddDomainForm.css';

function AddDomainForm({ onAddDomain }) {
  const [domainName, setDomainName] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onAddDomain({ name: domainName });
    setDomainName('');
  };

  return (
    <form onSubmit={handleSubmit} className="add-domain-form">
      <input
        type="text"
        value={domainName}
        onChange={(e) => setDomainName(e.target.value)}
        placeholder="Enter domain name"
        required
      />
      <button type="submit" className="btn add-btn">Add Domain</button>
    </form>
  );
}

export default AddDomainForm;
