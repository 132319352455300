import React from 'react';
import '../styles/DomainList.css';

function DomainList({ domains, onUpdateDomain, onDeleteDomain }) {
  return (
    <div className="domain-list">
      {domains.map(domain => (
        <div key={domain.id} className="domain-item">
          <h3>{domain.name}</h3>
          <p>TLD: {domain.tld}</p>
          <p>Registration Date: {domain.registration_date}</p>
          <p>Expiration Date: {domain.expiration_date}</p>
          <p>Nameservers: {domain.nameservers.join(', ')}</p>
          <p>Registrar: {domain.registrar}</p>
          <button onClick={() => onUpdateDomain(domain.id, { ...domain })} className="btn update-btn">Update</button>
          <button onClick={() => onDeleteDomain(domain.id)} className="btn delete-btn">Delete</button>
        </div>
      ))}
    </div>
  );
}

export default DomainList;
